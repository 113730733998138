import React from 'react';
import moment from 'moment';

const Card = ({ data, onClick }) => {
  const todaysDate = new Date();
  const isDateExpired = moment(data.data, 'DD.MM.YYYY').isBefore(moment(todaysDate), 'day');

  return (
    <div className={`rounded-lg shadow-md p-4 mb-4 hover:shadow-xl transition duration-300 cursor-pointer ${isDateExpired ? 'border border-solid border-red-500 bg-red-100 animate-pulse' : 'bg-white'}`}>
      <p className="text-lg font-semibold mb-2">Тип заявки: {data.type}</p>
      <div className="grid grid-cols-2 gap-4">
        <div className="flex flex-col">
          <p className="text-gray-600 mb-1">Телефон</p>
          <p className="text-gray-800 font-semibold">{data.phone}</p>
        </div>
        <div className="flex flex-col">
          <p className="text-gray-600 mb-1">Имя</p>
          <p className="text-gray-800 font-semibold">{data.name}</p>
        </div>
        <div className="flex flex-col">
          <p className="text-gray-600 mb-1">Модель устройства</p>
          <p className="text-gray-800 font-semibold">{data.model}</p>
        </div>
        <div className="flex flex-col">
          <p className="text-gray-600 mb-1">Дата</p>
          <p className="text-gray-800 font-semibold">{data.data}</p>
        </div>
        <div className="flex flex-col">
          <p className="text-gray-600 mb-1">Описание</p>
          <p className="text-gray-800 font-semibold">{data.opisanie}</p>
        </div>
        <div className="flex flex-col">
          <p className="text-gray-600 mb-1">Статус</p>
          <p className="text-gray-800 font-semibold">{data.status}</p>
        </div>
      </div>

      <div className="flex flex-col">
        <p className="text-gray-600 mb-1">Адрес</p>
        <p className="text-gray-800 font-semibold">{data.adress}</p>
      </div>

      <div className="flex justify-between items-center mt-4">
        <button className="bg-green-500 hover:bg-green-700 text-white font-semibold py-2 px-4 rounded focus:outline-none">Принять</button>
        <button className="bg-blue-500 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded focus:outline-none">Перенести</button>
      </div>

      <button className="bg-red-500 hover:bg-red-700 text-white font-semibold py-2 px-4 mt-2 rounded focus:outline-none">Закрыть</button>
    </div>
  );
};

export default Card;