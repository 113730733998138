import React, { useContext, useState, useEffect } from 'react';
import './ProfilePage.css';
import { motion } from 'framer-motion';
import { observer } from 'mobx-react-lite';
import { Context } from "../../../../../index";
import { API_URL } from '../../../../../http';
import { FaCheckCircle, FaTimesCircle, FaTelegram, FaTrash } from 'react-icons/fa';
import axios from 'axios';
import { success, errors } from '../../alerts/alerting_service';
import ReactLoading from "react-loading";

const User = () => {
  const [isLoading, setIsLoading] = useState(false);
  const pageMotion = {
    initial: { opacity: 0, x: 50, width: '90%' },
    animate: { opacity: 1, x: 0, width: '100%', transition: { duration: 0.3 } },
    exit: { opacity: 0, x: 50, width: '90%', transition: { duration: 0.3 } }
  };
  const [familia, setFamilia] = useState('');
  const [imya, setImya] = useState('');
  const [otchestvo, setOtchestvo] = useState('');
  const { store } = useContext(Context);
  const [pinCode, setPinCode] = useState('');
  const [showPinInput, setShowPinInput] = useState(false); // Добавлено состояние для отображения поля ввода пин-кода
  const [showLinkTelegram, setShowLinkTelegram] = useState(true);
  const [telegramAct, setTelegramAct] = useState(false); // Добавлено состояние для отображения статуса Telegram
  const [phoneAct, setPhoneAct] = useState(false); // Добавлено подтврждения телефона
  const [timer, setTimer] = useState(60); // Добавлено состояние для таймера

  const handleTgId = async (botUsername, params) => {
    setIsLoading(true);
    try {
      const botLink = `https://t.me/${botUsername}?start=${params}`;
      window.open(botLink);
      await new Promise(resolve => setTimeout(resolve, 1000)); // Минимальное время отображения анимации загрузки
      setShowPinInput(true); // После открытия Telegram добавляем поле для ввода пин-кода
      setShowLinkTelegram(false);
      startTimer(); // Запускаем таймер
    } catch (error) {
      console.error('Error saving profile:', error);
      errors('Ошибка при привязке телеграма', 3);
    } finally {
      setIsLoading(false);
    }
  }

  const startTimer = () => {
    const countdown = setInterval(() => {
      setTimer(prevTimer => {
        if (prevTimer <= 1) {
          clearInterval(countdown);
          setShowPinInput(false);
          setShowLinkTelegram(true);
          return 60;
        }
        return prevTimer - 1;
      });
    }, 1000);
  }

  const handlePinCodeConfirm = async () => {
    setIsLoading(true);
    try {
      // Отправка пин-кода на сервер для подтверждения привязки Telegram
      const response = await axios.post(`${API_URL}/confirm-telegram-link`, { idProfile: store.user.id, pinCode });
      await new Promise(resolve => setTimeout(resolve, 1000)); // Минимальное время отображения анимации загрузки
      setTelegramAct(true); // Устанавливаем статус Telegram как привязанный
      success('Привязка Telegram подтверждена', 3);
    } catch (error) {
      errors(error.response.data, 3);
    } finally {
      setIsLoading(false);
    }
  }

  const handleUnlinkTelegram = async () => {
    setIsLoading(true);
    try {
      const response = await axios.post(`${API_URL}/unlink-telegram-link`, { idProfile: store.user.id });
      // Логика для отвязки Telegram
      await new Promise(resolve => setTimeout(resolve, 1000)); // Минимальное время отображения анимации загрузки
      setTelegramAct(false); // Устанавливаем статус Telegram как не привязанный
      success('Telegram отвязан', 3);
    } catch (error) {
      console.error('Ошибка при отвязке Telegram:', error);
      errors('Ошибка при отвязке Telegram', 3);
    } finally {
      setIsLoading(false);
    }
  }

  const handleSave = async (id, familia, imya, otchestvo) => {
    setIsLoading(true);

    try {
      const response = await axios.post(`${API_URL}/saveprofile`, { id, familia, imya, otchestvo });
      await new Promise(resolve => setTimeout(resolve, 1000)); // Минимальное время отображения анимации загрузки
      setFamilia(response.data.profile.familia);
      setImya(response.data.profile.imya);
      setOtchestvo(response.data.profile.otchestvo);
      success('Сохранение успешно', 3);

    } catch (error) {
      console.error('Error saving profile:', error);
      errors('Ошибка при сохранении', 3);
    }
    setIsLoading(false);
  }

  useEffect(() => {
    const fetchUserProfile = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(`${API_URL}/loadprofileById/${store.user.id}`);
        await new Promise(resolve => setTimeout(resolve, 1000)); // Минимальное время отображения анимации загрузки
        setFamilia(response.data.familia);
        setImya(response.data.imya);
        setOtchestvo(response.data.otchestvo);
        setTelegramAct(response.data.telegramAct); // Устанавливаем статус Telegram из профиля
      } catch (error) {
        console.error('Error fetching user profile:', error);
      } finally {
        setIsLoading(false);
      };
    }

    fetchUserProfile();
  }, [store.user.id]);

  return (
    <motion.div initial="initial" animate="animate" exit="exit" variants={pageMotion}>
      {isLoading ? (
        <div className="flex items-center justify-center h-full w-full flex-col">
          <ReactLoading type="spinningBubbles" color="#103d77" height={100} width={100} />
          <h2>Загрузка, пожалуйста подождите...</h2>
        </div>
      ) : (
        <div className="flex relative flex-row items-start justify-center w-full p-4 gap-4 flex-wrap">
          <div className="flex relative bg-white shadow-lg rounded-lg p-3 flex-1 min-w-96">
            <div className='flex relative flex-col w-full'>
              <h2 className="text-2xl font-bold mb-2">Данные пользователя</h2>
              <div className="grid grid-cols-1 gap-2">
                <div>
                  <label className="block text-sm font-medium text-gray-700" htmlFor="surname">Фамилия:</label>
                  <input
                    type="text"
                    name="surname"
                    onChange={e => setFamilia(e.target.value)}
                    value={familia}
                    placeholder="Фамилия"
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700" htmlFor="name">Имя:</label>
                  <input
                    type="text"
                    name="name"
                    onChange={e => setImya(e.target.value)}
                    value={imya}
                    placeholder="Имя"
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700" htmlFor="patronymic">Отчество:</label>
                  <input
                    type="text"
                    name="patronymic"
                    onChange={e => setOtchestvo(e.target.value)}
                    value={otchestvo}
                    placeholder="Отчество"
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                  />
                </div>
              </div>
              <div className="mt-2">
                <button
                  type="submit"
                  className="bg-indigo-500 text-white px-4 py-2 rounded-md"
                  onClick={() => handleSave(store.user.id, familia, imya, otchestvo)}
                  disabled={isLoading}
                >
                  Сохранить изменения
                </button>
              </div>
              <h2 className="text-2xl font-bold mt-6 mb-4">Контакты</h2>
              <div className="grid grid-cols-1 gap-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700" htmlFor="phone">Телефон:</label>
                  <div className='flex flex-row gap-2'>
                    <input
                      type="tel"
                      name="phone"
                      value={store.user.phone}
                      placeholder="Телефон"
                      readOnly
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                    />
                    {phoneAct ? (
                      <div className="flex items-center space-x-2 text-green-500">
                        <FaCheckCircle />
                      </div>
                    ) : (
                      <div className="flex items-center space-x-2 text-red-500">
                        <FaTimesCircle />
                      </div>
                    )}
                  </div>
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700" htmlFor="email">Электронная почта:</label>
                  <div className='flex flex-row gap-2'>
                    <input
                      type="email"
                      name="email"
                      value={store.user.email}
                      placeholder="Email"
                      readOnly
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                    />
                    {store.user.isActivated ? (
                      <div className="flex items-center space-x-2 text-green-500">
                        <FaCheckCircle />
                      </div>
                    ) : (
                      <div className="flex items-center space-x-2 text-red-500">
                        <FaTimesCircle />
                      </div>
                    )}
                  </div>
                </div>
                <h2 className="text-2xl font-bold">Социальные сети</h2>
                <div className="grid grid-cols-1 gap-4">
                  <div className="mt-4">
                    {!telegramAct && <>
                      {showLinkTelegram && <button onClick={() => handleTgId('PrintServicBot', store.user.id)} className="bg-blue-500 text-white px-4 py-2 rounded-md">Привязать Telegram</button>}
                      {showPinInput && <input type="text" value={pinCode} onChange={(e) => setPinCode(e.target.value)} className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" />}
                      {showPinInput && <button onClick={handlePinCodeConfirm} className="bg-green-500 text-white px-4 py-2 rounded-md mt-2">Подтвердить</button>}
                      {showPinInput && <p className="text-sm text-gray-500 mt-2">Оставшееся время: {timer} секунд</p>}
                    </>
                    }
                    {telegramAct && (
                      <div className="flex items-center space-x-2">
                        <div className='flex relative w-10 h-10'>
                          <FaTelegram className='w-full h-full text-blue-500' />
                          <FaCheckCircle className="text-green-500 absolute right-[-5px] top-[-5px] bg-white rounded-full" />
                          <button onClick={handleUnlinkTelegram} className="absolute right-[-5px] bottom-[-5px]">
                            <FaTrash className="text-red-500 hover:scale-150 transition-transform" />
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-white shadow-md rounded-lg p-6 w-80 mt-6">
            <h3 className="text-xl font-bold mb-4">Организация</h3>
            <div className="grid grid-cols-1 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">Организация:</label>
                <p className="mt-1 text-gray-900">организация</p>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Должность:</label>
                <p className="mt-1 text-gray-900">должность</p>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Филиал:</label>
                <p className="mt-1 text-gray-900">филиал</p>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Адрес:</label>
                <p className="mt-1 text-gray-900">адрес</p>
              </div>
            </div>
          </div>
        </div>
      )}
    </motion.div>
  );
};

export default observer(User);