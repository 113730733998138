import React, { useContext, useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { observer } from 'mobx-react-lite';
import { Context } from "../../../../../index";
import { API_URL } from '../../../../../http';
import axios from 'axios';
import ReactLoading from "react-loading";
import { useNavigate } from 'react-router-dom';

const ZayavkiSSite = () => {
  const [selectedZayavka, setSelectedZayavka] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [zayssite, setZayavki] = useState([]);
  const [activeTab, setActiveTab] = useState('Новая');
  const [searchQuery, setSearchQuery] = useState('');

  const { store } = useContext(Context);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchAllZapSSite = async (activeTab) => {
      setIsLoading(true);
      try {
        const response = await axios.get(`${API_URL}/zayssite/zayssiteall?category=${activeTab}`);
        setZayavki(response.data.zayssite);
      } catch (error) {
        console.error('Error fetching user profile:', error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchAllZapSSite(activeTab);
  }, [store.user.id, activeTab]);

  const filteredZayavki = zayssite.filter((zay) => {
    const isCategoryMatch = activeTab === 'Новая' ? zay.status === 'Новая' : zay.status === activeTab;
    const isSearchMatch =
      zay.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      zay.phone.toLowerCase().includes(searchQuery.toLowerCase()) ||
      zay.opisanie.toLowerCase().includes(searchQuery.toLowerCase()) ||
      (zay.data + ' ' + zay.time).toLowerCase().includes(searchQuery.toLowerCase()) ||
      (zay.dataof + ' ' + zay.timeof).toLowerCase().includes(searchQuery.toLowerCase());
    return isCategoryMatch && isSearchMatch;
  });

  const handleTabChange = (category) => {
    setActiveTab(category);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'Новая':
        return 'bg-green-500';
      case 'В работе':
        return 'bg-yellow-500';
      case 'Закрыто':
        return 'bg-gray-500';
      default:
        return 'bg-gray-300';
    }
  };

  return (
    <motion.div initial="initial" animate="animate" exit="exit" className="p-4 w-full">
      <div className="flex flex-col gap-4 w-full min-h-full">
        <div className="flex items-center justify-between mb-4">
          <h1 className="text-2xl font-semibold text-gray-900">Вопросы с сайта</h1>
          <div className="flex space-x-2">
            <button
              className={`px-4 py-2 rounded-lg text-white ${activeTab === 'Новая' ? 'bg-green-600' : 'bg-gray-300'} hover:bg-green-700 transition duration-150`}
              onClick={() => handleTabChange('Новая')}
            >
              Новые
            </button>
            <button
              className={`px-4 py-2 rounded-lg text-white ${activeTab === 'Закрыто' ? 'bg-gray-600' : 'bg-gray-300'} hover:bg-gray-700 transition duration-150`}
              onClick={() => handleTabChange('Закрыто')}
            >
              Архив
            </button>
            <input
              className="border rounded-lg p-2 text-gray-700 w-48"
              type="text"
              placeholder="Поиск..."
              value={searchQuery}
              onChange={handleSearchChange}
            />
          </div>
        </div>

        {isLoading ? (
          <div className="flex justify-center items-center">
            <ReactLoading type="spinningBubbles" color="#4A90E2" height={50} width={50} />
          </div>
        ) : (
          <div className="space-y-4">
            {filteredZayavki.map((zay) => (
              <div
                key={zay._id}
                className="bg-white rounded-lg p-4 cursor-pointer hover:bg-slate-100 duration-200 shadow-sm transition-transform transform hover:scale-105"
                onClick={() => navigate(`card/${zay._id}`)}
              >
                <div className="flex items-center space-x-4">
                  <div className={`w-4 h-4 rounded-full ${getStatusColor(zay.status)}`}></div>
                  <div className="flex-1">
                    <div className="text-lg font-semibold">{zay.name}</div>
                    <div className="text-sm text-gray-600">{zay.phone}</div>
                  </div>
                  <div className="mt-2 text-sm text-gray-500">
                    <div>Создано: {zay.data} {zay.time}</div>
                    <div>Решено: {zay.dataof} {zay.timeof}</div>
                  </div>
                </div>
                <div className="mt-2 text-gray-800">{zay.opisanie}</div>
              </div>
            ))}
          </div>
        )}
      </div>
    </motion.div>
  );
};

export default observer(ZayavkiSSite);
