import { Observable, Subject } from 'rxjs';

import { IAlert } from '../../../../models/alert';
import { TColors } from '../../../../models/types/TColors';

const alertsSubject = new Subject();
const closedAlertsSubject = new Subject();

const success = (message, timeout = 0) => {
  alert('success', message, timeout);
};

const errors = (message, timeout = 0) => {
  alert('errors', message, timeout);
};

const warning = (message, timeout = 0) => {
  alert('warning', message, timeout);
};

const info = (message, timeout = 0) => {
  alert('info', message, timeout);
};

const close = (id) => {
  closedAlertsSubject.next(id);
};

const alert = (status, message, timeout) => {
  alertsSubject.next({
    id: Math.round(window.performance.now()*10),
    status, message, timeout
  });
};

const onAlert = () => {
  return alertsSubject
    .asObservable();
};

const onClosed = () => {
  return closedAlertsSubject
    .asObservable();
};

export {
  success,
  warning,
  errors,
  info,
  close,
  onClosed,
  onAlert,
};
