import React, { useContext, useState, useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { motion } from 'framer-motion';
import './Components/alerts/CenteredVerticalForm/index';
import { AlertingService } from './Components/alerts/Alert/alerting_service';
import { CenteredVerticalForm } from './Components/alerts/CenteredVerticalForm/centered_vertical_form';

// Components
import Navigation from './Components/Navigation/Navigation';
import AnimatedRoutes from './AnimatedRoutes';
import Heaser from './Components/Header/Header'
import Header from './Components/Header/Header';
const Dashboard = () => {
    const pageMotion = {
        initial: { opacity: 0, x: 50, maxWidth: '90%' },
        animate: { opacity: 1, x: 0, maxWidth: '100%', transition: { duration: 0.3 } },
        exit: { opacity: 0, x: 50, maxWidth: '90%', transition: { duration: 0.3 } }
    };
    const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);

    return (
        <Router>
            <motion.div initial="initial" animate="animate" exit="exit" variants={pageMotion}>
                <Header />
                <aside id="logo-sidebar" className={`fixed top-0 left-0 z-40 ${isSidebarOpen ? 'translate-x-0' : ''} w-64 h-screen pt-20 transition-transform -translate-x-full bg-gray-900 sm:translate-x-0`} aria-label="Sidebar">
                    <Navigation />
                </aside>
                <div className=" p-3 min-h-[calc(100vh-50px)] sm:ml-64">
                    <CenteredVerticalForm>
                        <AlertingService
                            horizontal={'right'}
                            vertical={'bottom'}
                        />
                        <div className="flex relative w-full bg-gray-50 p-2 border-2 border-dashed rounded-lg mt-14 min-h-[calc(100vh-150px)]">

                            <AnimatedRoutes />

                        </div>
                    </CenteredVerticalForm>
                </div>
                <footer className='flex relative bottom-0 mt-auto z-50 w-full h-[50px] bg-slate-800 items-center justify-center content-center text-center text-slate-50'>
                    <p>© 2024 Принт сервис   |   </p>
                </footer>
            </motion.div>
        </Router>
    );
};

export default observer(Dashboard);
