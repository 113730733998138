import React, { useEffect, useContext, useState } from 'react';
import { Context } from '../../index';
import { observer } from 'mobx-react-lite';
import InputMask from 'react-input-mask';

const AuthPage = () => {
  const [isLoginFormVisible, setLoginFormVisible] = useState(true);
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const { store } = useContext(Context);

  const validateForm = (formValidCallback) => {
    let isValid = true;
    if (email.trim() === '' || phone.trim() === '' || password.trim() === '') {
      setError('Пожалуйста, заполните все поля');
      isValid = false;
    } else {
      setError('');
      isValid = true;
    }
    formValidCallback(isValid);
  };

  const validateFormLogin = (formValidCallback) => {
    let isValid = true;
    if (phone.trim() === '' || password.trim() === '') {
      setError('Пожалуйста, заполните все поля');
      console.log('Пожалуйста, заполните все поля');
      isValid = false;
    } else {
      setError('');
      isValid = true;
    }
    formValidCallback(isValid);
  };

  const handleRegister = () => {
    validateForm((isValid) => {
      if (isValid) {
        const phoneDigits = phone.replace(/\D/g, ''); // Извлекаем только цифры из телефона
        store.registration(phoneDigits, email, password)
          .then(() => {
            // Обработка успешного входа
            setError(''); // Очищаем ошибку при успешном входе
          })
          .catch((error) => {
            setError('Неверный пароль');
          });
      }
    });
  };

  const handleLogin = () => {
    validateFormLogin((isValid) => {
      if (isValid) {
        const phoneDigits = phone.replace(/\D/g, ''); // Извлекаем только цифры из телефона
        store.login(phoneDigits, password)
          .then(() => {
            // Обработка успешного входа
            setError(''); // Очищаем ошибку при успешном входе
          })
          .catch((error) => {
            setError('Неверный пароль');
          });
      }
    });
  };

  const handleTabClick = (isLogin) => {
    setLoginFormVisible(isLogin);
  };

  return (
    <section className="flex relative items-center justify-center content-center gradient-form w-full min-h-screen bg-neutral-200">
      <div className="container h-full p-5">
        <div
          className="flex h-full flex-wrap items-center justify-center text-neutral-800">
          <div className="w-full">
            <div
              className="block rounded-lg bg-white shadow-lg">
              <div className="g-0 flex relative flex-row">

                <div className="px-4 md:px-0 lg:w-6/12">
                  <div className="md:mx-6 md:p-5">

                    <div className="text-center">
                      <img
                        className="mx-auto w-40"
                        src={require('../../icon.png')}
                        alt="logo" />
                      <h4 className="mb-8 mt-1 pb-1 text-xl font-semibold">
                        Добро пожаловать в Ваш личный кабинет
                      </h4>
                    </div>

                    <form className='flex relative flex-col gap-2'>
                      <p className="mb-4">Пожалуйста введите данные аккаунта</p>


                      {isLoginFormVisible ? (
                        <div />
                      ) : (
                        <div className="relative mb-4" data-twe-input-wrapper-init>
                          <input
                            onChange={e => setEmail(e.target.value)}
                            value={email}
                            type="text"
                            className="peer block min-h-[auto] w-full rounded border-2 bg-transparent px-3 py-[0.32rem] leading-[1.6] outline-none"
                            placeholder='Email'
                          />
                          <label
                            className="pointer-events-none absolute left-3 -top-[1.2rem] mb-0 max-w-[90%] origin-[0_0] truncate pt-[0.37rem] leading-[1.6] text-neutral-500 bg-white pl-2 pr-2"
                          >
                            Электронная почта
                          </label>
                        </div>
                      )}
                      <div className="relative mb-4" data-twe-input-wrapper-init>
                        <InputMask
                          type="text"
                          mask="+7 (999) 999-99-99"
                          maskChar="_"
                          className="peer block min-h-[auto] w-full rounded border-2 bg-transparent px-3 py-[0.32rem] leading-[1.6] outline-none"
                          onChange={(e) => setPhone(e.target.value)}
                          value={phone}
                        />
                        <label
                          className="pointer-events-none absolute left-3 -top-[1.2rem] mb-0 max-w-[90%] origin-[0_0] truncate pt-[0.37rem] leading-[1.6] text-neutral-500 bg-white pl-2 pr-2"
                        >
                          Номер телефона
                        </label>
                      </div>

                      <div className="relative mb-4" data-twe-input-wrapper-init>
                        <input
                          type="password"
                          className="peer block min-h-[auto] w-full rounded border-2 bg-transparent px-3 py-[0.32rem] leading-[1.6] outline-none"
                          onChange={e => setPassword(e.target.value)}
                          value={password}
                        />
                        <label
                          className="pointer-events-none absolute left-3 -top-[1.2rem] mb-0 max-w-[90%] origin-[0_0] truncate pt-[0.37rem] leading-[1.6] text-neutral-500 bg-white pl-2 pr-2"
                        >Пароль
                        </label>
                      </div>



                      <div className="mb-12 pb-1 pt-1 text-center">

                        {isLoginFormVisible ? (
                          <button
                            className="mb-3 inline-block w-full bg-slate-800 rounded px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-dark-3 transition duration-150 ease-in-out hover:border-primary-600 hover:scale-y-110 hover:bg-neutral-900 hover:bg-opacity-70 hover:text-primary-600 focus:border-primary-600 focus:text-primary-600 focus:outline-none focus:ring-0 active:border-primary-700 active:text-primary-700"
                            onClick={handleLogin}
                          >
                            Вход
                          </button>
                        ) : (
                          <button
                            className="mb-3 inline-block w-full bg-slate-800 rounded px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-dark-3 transition duration-150 ease-in-out hover:border-primary-600 hover:scale-y-110 hover:bg-neutral-900 hover:bg-opacity-70 hover:text-primary-600 focus:border-primary-600 focus:text-primary-600 focus:outline-none focus:ring-0 active:border-primary-700 active:text-primary-700"
                            onClick={handleRegister}>
                            Регистрация
                          </button>
                        )}

                      </div>

                      {isLoginFormVisible ? (
                        <div className="flex items-center justify-between pb-2">
                          <p className="mb-0 me-2">Нет аккаунта?</p>
                          <div className="inline-block cursor-pointer rounded border-2 border-danger px-6 pb-[6px] pt-2 text-xs font-medium uppercase leading-normal text-danger transition duration-150 ease-in-out hover:border-slate-900 hover:bg-slate-100 hover:text-slate-900 focus:border-danger-600 focus:bg-danger-50/50 focus:text-danger-600 focus:outline-none focus:ring-0 active:border-danger-700 active:text-danger-700" onClick={() => handleTabClick(false)}>Регистрация</div>
                        </div>
                      ) : (

                        <div className="flex items-center justify-between pb-2">
                          <p className="mb-0 me-2">Уже есть аккаунт?</p>
                          <div className="inline-block cursor-pointer rounded border-2 border-danger px-6 pb-[6px] pt-2 text-xs font-medium uppercase leading-normal text-danger transition duration-150 ease-in-out hover:border-slate-900 hover:bg-slate-100 hover:text-slate-900 focus:border-danger-600 focus:bg-danger-50/50 focus:text-danger-600 focus:outline-none focus:ring-0 active:border-danger-700 active:text-danger-700" onClick={() => handleTabClick(true)}>Вход</div>
                        </div>
                      )}

                    </form>
                  </div>
                </div>

                <div
                  className="hidden items-center flex-wrap w-1/2 justify-center content-center relative bg-slate-800 lg:block"
                >
                  <div className="flex h-full relative flex-col px-4 py-6 text-white items-center justify-center content-center md:mx-6 md:p-12">
                    <h4 className="mb-6 text-xl font-semibold">
                      Группа компаний "Принт-Сервис"
                    </h4>
                    <p className="text-sm">
                      Группа компаний «Принт-Сервис» предлагает широкий спектр услуг по ремонту и обслуживанию различной техники. Мы специализируемся на ремонте печатной офисной техники, ноутбуков и другой аппаратуры. Наши опытные специалисты используют только качественные запчасти и обеспечивают высокое качество ремонта. Мы предоставляем гарантию на выполненные работы и предлагаем доступные цены. Обращайтесь в наш сервисный центр, и мы поможем вернуть вашу технику к жизни!
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default observer(AuthPage);