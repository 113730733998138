import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
import * as XLSX from 'xlsx'; // Import all functions from xlsx library
import axios from 'axios';

Modal.setAppElement('#root');

const Proisv = () => {
  const navigate = useNavigate();
  const [producers, setProducers] = useState([]);
  const [isImporting, setIsImporting] = useState(false);
  const [progress, setProgress] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const pageMotion = {
    initial: { opacity: 0, x: 50, maxWidth: '90%' },
    animate: { opacity: 1, x: 0, maxWidth: '100%', transition: { duration: 0.3 } },
    exit: { opacity: 0, x: 50, maxWidth: '90%', transition: { duration: 0.3 } }
  };

  const handleSave = async (proisvCart, sovmPrint, nameCart, resurs, cenaZapr, cenaChip, cenaFoto, cenaLezv, cenaRolica, cenaCart) => {
    setIsLoading(true);

    try {
      const response = await axios.post(`https://lk.eq74.ru/api/cart/cartadd`, { proisvCart, sovmPrint, nameCart, resurs, cenaZapr, cenaChip, cenaFoto, cenaLezv, cenaRolica, cenaCart });
      console.log('Картридж ' + nameCart + ' успешно добавлен');
      
    } catch (error) {
      console.error('Error saving profile:', error);

    }
    setIsLoading(false);
  }

  const handleImportData = async (file) => {
    setIsImporting(true);
    const reader = new FileReader();
    reader.onload = async (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(sheet);

      for (let i = 0; i < jsonData.length; i++) {
        const row = jsonData[i];
        // Send row data to server via API
        // Update progress bar

        console.log(row);
        handleSave(row.proisvCart, row.sovmPrint, row.nameCart, row.resurs, row.cenaZapr, row.cenaChip, row.cenaFoto, row.cenaLezv, row.cenaRolica, row.cenaCart);

        setProgress(((i + 1) / jsonData.length) * 100);
      }

      setIsImporting(false);
    };

    reader.readAsArrayBuffer(file);
  };

  useEffect(() => {
    const fetchProducers = async () => {
      try {
        const response = await fetch('https://lk.eq74.ru/api/cart/proisvall');
        
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setProducers(data);
      } catch (error) {
        console.error("Failed to load producers list:", error);
      }
    };

    fetchProducers();
  }, []);

  return (
    <motion.div initial="initial" animate="animate" exit="exit" variants={pageMotion}>
      <div className="flex relative w-full flex-row flex-wrap justify-center items-center gap-20">
        {producers.map((producer, index) => (
          <div key={index} id="step-0" className="flex flex-col w-48 min-w-48 h-28 bg-slate-100 justify-end relative shadow-xl transition hover:-translate-y-[20px]"
            onClick={() => navigate(`/cart/${producer._id}`)}
          >
            <div className="absolute self-center top-[-20px]">
              <img alt="Moneyback" loading="lazy" width="142" height="134" decoding="async" data-nimg="1" className="" src={producer.urlProisv} />
            </div>
            <div className="pl-[10px] pr-[10px] pb-[10px] text-xl font-[700] text-center">
              {producer.nameProisv}
            </div>
          </div>
        ))}
      </div>
      <Modal isOpen={isImporting}>
        <div>Importing data...</div>
        <progress value={progress} max={100}></progress>
      </Modal>
      <input type="file" onChange={(e) => handleImportData(e.target.files[0])} />
    </motion.div>
  );
};

export default Proisv;
