import cl from 'classnames';
import { useEffect, useState } from 'react';

import { IAlert } from '../../../../../models/alert';

import { Alert } from './alert';
import { onAlert, onClosed } from '../alerting_service';
import styles from './alerting_service.module.scss';

export const AlertingService = (props) => {
  const [alerts, setAlerts] = useState([]);
  
  const alertsContent = alerts.map((alert) => {
    return <Alert 
      key={alert.id}
      {...alert}
    />;
  });

  useEffect(() => {
    const onAlertSubscription$ = onAlert().subscribe(v => {
      setAlerts([
        ...alerts,
        v,
      ]);
    });
    const onClosedSubscription$ = onClosed().subscribe(id => {
      setAlerts(
        alerts.filter(alert => alert.id !== id),
      );      
    });

    return () => {
      onAlertSubscription$.unsubscribe();
      onClosedSubscription$.unsubscribe();
    };
  }, [alerts]);

  return (
    <div className={cl(
      styles.default,
      styles[props.horizontal],
      styles[props.vertical],
    )}>
      {alertsContent}
    </div>
  );
};
