import React, { useContext, useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { observer } from 'mobx-react-lite';
import { Context } from "../../../../../index";
import { API_URL } from '../../../../../http';
import axios from 'axios';
import { success, errors, warning, info } from '../../alerts/alerting_service';
import { Routes, Route, Link, useParams, useNavigate } from 'react-router-dom';
import ReactLoading from "react-loading";
//import { IProfile } from "../../../../models/IProfile";

import Column from './Column';
import CardModal from './CardModal';


const KurierPage = () => {
  const [data, setData] = useState([]);
  const [selectedCard, setSelectedCard] = useState(null);

  const fetchData = async () => {
    try {
      const response = await fetch(`${API_URL}/zayssitecur/zayssitecurall`);
      const jsonData = await response.json();
      setData(jsonData.zaycur);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleCardClick = (cardData) => {
    setSelectedCard(cardData);
  };

  const today = new Date();
  const todayCards = data.filter(card => {
    const cardDate = new Date(card.data.split('.').reverse().join('-'));
    cardDate.setHours(0, 0, 0, 0);
    return card.status === 'Новая' && cardDate <= today
  });

  const sortedCards = todayCards.sort((a, b) => {
    const aDate = new Date(a.data);
    const bDate = new Date(b.data);
    return aDate - bDate;
  });

  if (sortedCards.length > 0) {
    sortedCards[0].highlight = true;
  }

  const tomorrow = new Date();
tomorrow.setDate(tomorrow.getDate() + 1);
tomorrow.setHours(0, 0, 0, 0);

const tomorrowCards = data.filter(card => {
    const cardDate = new Date(card.data.split('.').reverse().join('-'));
    cardDate.setHours(0, 0, 0, 0);

    console.log("Дата завтра " + tomorrow);
    console.log("Дата в заявке " + cardDate);

    return card.status === 'Новая' && cardDate.getTime() === tomorrow.getTime();
});

  const maxDate = new Date();
  maxDate.setDate(today.getDate() + 2);

  const laterCards = data.filter(card => {
    const cardDate = new Date(card.data.split('.').reverse().join('-'));
    return card.status === 'Новая' && cardDate > maxDate;
  });

const [activeColumn, setActiveColumn] = useState('В работе');

const handleMenuClick = (columnTitle) => {
  setActiveColumn(columnTitle);
};

return (
  <div className="flex flex-col md:flex-row gap-4">
    <Column title="В работе" data={data.filter(card => card.status === 'В работе')} onClick={handleCardClick} />
    <Column title="Новые на сегодня" data={todayCards} onClick={handleCardClick} />
    <Column title="Завтра" data={tomorrowCards} onClick={handleCardClick} />
    <Column title="Позднее" data={laterCards} onClick={handleCardClick} />
    {selectedCard && <CardModal card={selectedCard} onClose={() => setSelectedCard(null)} />}
  </div>
);
};

export default observer(KurierPage);