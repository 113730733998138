import React, { useContext, useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { observer } from 'mobx-react-lite';
import { Context } from "../../../../../index";
import { API_URL } from '../../../../../http';
import axios from 'axios';
import ReactLoading from "react-loading";
import { Link, useParams, useNavigate } from 'react-router-dom';
import './card.css';

const Card = () => {
  const pageMotion = {
    initial: { opacity: 0, x: 50 },
    animate: { opacity: 1, x: 0, transition: { duration: 0.3 } },
    exit: { opacity: 0, x: 50, transition: { duration: 0.3 } }
  };

  const [raboti, setRaboti] = useState([]);
  const { store } = useContext(Context);
  const [isLoading, setIsLoading] = useState(false);
  const [zremData, setZremData] = useState({});
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchZapSSiteById = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(`${API_URL}/zrem/zremById/${id}`);
        setZremData(response.data);
        setRaboti(response.data.raboti);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchZapSSiteById();
  }, [id]);

  const statusColors = {
    'Принят в ремонт': 'bg-emerald-500',
    'Требует согласования': 'bg-emerald-800',
    'Согласовано': 'bg-green-600',
    'Необходимо заказать ЗИП': 'bg-red-600',
    'Ожидает ЗИП': 'bg-red-400',
    'ЗИП Получен': 'bg-green-700',
    'Оповестить клиента': 'bg-yellow-400',
    'К выдаче': 'bg-gray-600',
    'Выдан покупателю': 'bg-gray-300 text-gray-600',
    default: 'bg-gray-50',
  };

  const getStatusColor = (status) => statusColors[status] || statusColors.default;

  return (
    <motion.div initial="initial" animate="animate" exit="exit" variants={pageMotion} className="p-2 w-full mx-auto">
      <div className='bg-white rounded-lg shadow-lg p-2'>
        {isLoading ? (
          <div className="flex justify-center items-center">
            <ReactLoading type="spinningBubbles" color="#103d773b" height={50} width={50} />
          </div>
        ) : (
          <div className='flex flex-col gap-4'>
            <div className='bg-gray-900 text-white p-4 rounded-lg shadow-md '>
              <div className="flex items-center space-x-4 text-white">
                <button
                  className='px-4 py-2 bg-gray-700 text-white rounded-lg shadow-md hover:bg-gray-600 transition'
                  onClick={() => navigate(-1)}
                >
                  Назад
                </button>
                <div className={`w-4 h-4 rounded-full ${getStatusColor(zremData?.status)}`}></div>
                <div className="flex-1">
                  <div className="text-sm text-white">{zremData?.status}</div>
                  <div className="text-lg font-semibold text-white">{zremData?.numberremonta}</div>
                </div>
                <div className="mt-2 text-sm text-white">
                  <div>Принят: {zremData?.datapriemki}</div>
                  <div>Изменен: {zremData?.dataizmst}</div>
                </div>
              </div>
            </div>
            <div className="bg-gray-100 p-4 rounded-lg shadow-md gap-4">
              <div className='text-gray-600 p-2 rounded-lg'>
                <h3 className='text-lg font-semibold'>Информация о пользователе</h3>
              </div>

              <div className="flex items-center space-x-4">
                <div className="flex-1">
                  <div className="text-sm text-gray-600">Клиент:</div>
                  <div className="text-lg font-semibold">{zremData?.klient}</div>
                </div>
                <div className="flex-1">
                  <div className="text-sm text-gray-600">Телефон</div>
                  <div className="text-lg font-semibold">{zremData?.phone}</div>
                </div>
                <div className="flex-1">
                  <div className="text-sm text-gray-600">ИНН:</div>
                  <div className="text-lg font-semibold">{zremData?.kontrinn}</div>
                </div>
                <div className="flex-1">
                  <div className="text-sm text-gray-600">Организация</div>
                  <div className="text-lg font-semibold">{zremData?.kontr}</div>
                </div>
              </div>

              <div className='text-gray-600 p-2 rounded-lg'>
                <h3 className='text-lg font-semibold'>Информация об устройстве</h3>
              </div>

              <div className="flex items-center space-x-4">
                <div className="flex-1">
                  <div className="text-sm text-gray-600">Устройство:</div>
                  <div className="text-lg font-semibold">{zremData?.nomenklatura}</div>
                </div>
                <div className="flex-1">
                  <div className="text-sm text-gray-600">Серийный номер:</div>
                  <div className="text-lg font-semibold">{zremData?.sernom}</div>
                </div>
              </div>

              <div className="flex items-center space-x-4">
                <div className="flex-1">
                  <div className="text-sm text-gray-600">Заявленная неисправность:</div>
                  <textarea className="mt-1 p-2 border rounded-lg bg-white text-gray-800 w-full" value={zremData?.neispravnost || ''} readOnly />
                </div>
              </div>
            </div>

            <div className='bg-gray-900 text-white p-4 rounded-lg shadow-md'>
              <h3 className='text-lg font-semibold'>Решение от сервисного центра</h3>
            </div>

            <div className='bg-gray-100 p-4 rounded-lg shadow-md'>
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-200">
                  <tr>
                    <th className="py-2 px-4 text-left text-gray-700">Наименование работ</th>
                    <th className="py-2 px-4 text-left text-gray-700">Кол-во</th>
                    <th className="py-2 px-4 text-left text-gray-700">Цена</th>
                    <th className="py-2 px-4 text-left text-gray-700">Сумма</th>
                  </tr>
                </thead>
                <tbody>
                  {raboti.map((item, index) => (
                    <tr key={index} className="transition hover:bg-gray-50">
                      <td className="py-2 px-4">{item.rabota}</td>
                      <td className="py-2 px-4 text-center">{item.kolichestvo}</td>
                      <td className="py-2 px-4 text-center">{item.tsena ? `${item.tsena} руб.` : '-'}</td>
                      <td className="py-2 px-4 text-center">{item.summa ? `${item.summa} руб.` : '-'}</td>
                    </tr>
                  ))}
                </tbody>
                <tfoot>
                  <tr className="bg-gray-200">
                    <td colSpan="3" className="py-2 px-4 font-semibold text-gray-700">Сумма ремонта:</td>
                    <td className="py-2 px-4 text-center">
                      <input className="w-full p-2 border border-gray-300 rounded-md" value={zremData.sumrem ? `${zremData.sumrem} руб.` : '-'} readOnly />
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        )}
      </div>
    </motion.div>
  );
};

export default observer(Card);
