import React from 'react';
import Card from './Card';

const Column = ({ title, data, onClick }) => {
  // Сортировка карточек по дате
  const sortedData = data.sort((a, b) => new Date(a.data.split('.').reverse().join('-')) - new Date(b.data.split('.').reverse().join('-')));

  return (
    <div className="bg-gray-200 p-4">
      <h2 className="text-lg font-bold mb-4">{title}</h2>
      {sortedData.map(card => <Card key={card.id} data={card} onClick={() => onClick(card)} />)}
    </div>
  );
};

export default Column;