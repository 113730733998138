import React from "react";
import logo from '../../icon.svg';
import ReactLoading from "react-loading";
import './LoadingScreen.css';

const LoadingScreen = () => {

  return (
        <div className="loading-screen">
            <div className="block-screen">
                <div className="loading-icon">
                    <img src={logo} className="load-logo" alt="logo" />
                </div>
            <h1>Группа компаний "Принт-Сервис"</h1> 
            <ReactLoading
                type="spokes"
                color="#fff"
                height={"30%"}
                width={"30%"}
            />
            </div>
        </div>
  );
};

export default LoadingScreen;