import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { API_URL } from '../../../../../http';
import ReactLoading from 'react-loading';

const OrganizationList = () => {
    const [organizations, setOrganizations] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const pageMotion = {
        initial: { opacity: 0, x: 50, width: '90%' },
        animate: { opacity: 1, x: 0, width: '100%', transition: { duration: 0.3 } },
        exit: { opacity: 0, x: 50, width: '90%', transition: { duration: 0.3 } }
    };

    // Загрузка списка организаций с сервера
    useEffect(() => {
        const fetchOrganizations = async () => {
            setIsLoading(true);
            try {
                const response = await axios.get(`${API_URL}/organizations/all`);
                setOrganizations(response.data);
            } catch (error) {
                console.log('Ошибка загрузки организаций:', error);
            } finally {
                setIsLoading(false);
            }
        };
        fetchOrganizations();
    }, []);

    // Поиск организаций по всем полям
    const filteredOrganizations = organizations.filter((org) => {
        const search = searchQuery.toLowerCase();
    
        return (
            (org.nameOrg && org.nameOrg.toLowerCase().includes(search)) ||
            (org.descriptionOrg && org.descriptionOrg.toLowerCase().includes(search)) ||
            (org.addressOrg && org.addressOrg.toLowerCase().includes(search)) ||
            (org.phoneOrg && org.phoneOrg.some(phone => phone.toLowerCase().includes(search))) ||
            (org.innOrg && org.innOrg.toLowerCase().includes(search)) ||
            (org.emailOrg && org.emailOrg.toLowerCase().includes(search))
        );
    });

    const handleCardClick = (org) => {
        //setSelectedUser(org);
        //setIsModalOpen(true);
    };

    // Обработчик изменения запроса поиска
    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    return (
        <motion.div initial="initial" animate="animate" exit="exit" variants={pageMotion} className="p-4 bg-gray-100 min-h-screen">
            {isLoading ? (
                <div className="flex flex-col items-center justify-center h-full w-full">
                    <ReactLoading type="spinningBubbles" color="#103d77" height={100} width={100} />
                    <h2 className="text-lg font-semibold mt-4">Загрузка, пожалуйста подождите...</h2>
                </div>
            ) : (
                <div className="space-y-6">
                    <div className="bg-white shadow-sm rounded-lg p-4">
                        <div className="flex flex-col md:flex-row md:items-center md:justify-between mb-4">
                            <h1 className="text-2xl font-bold text-gray-800">Список организаций</h1>
                            <div className="flex flex-col md:flex-row md:space-x-4">
                                <input
                                    type="text"
                                    placeholder="Поиск по имени, описанию, телефону или email..."
                                    value={searchQuery}
                                    onChange={handleSearchChange}
                                    className="mb-4 md:mb-0 p-2 border border-gray-300 rounded-lg w-full md:w-64"
                                />
                                <Link
                                    to="/add-organization"
                                    className="bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600 transition-colors"
                                >
                                    Добавить организацию
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="space-y-4">
                        {filteredOrganizations.map((org) => (
                            <div
                                key={org._id}
                                className="bg-white p-4 border border-gray-200 rounded-lg shadow-md hover:shadow-lg transition-shadow cursor-pointer"
                                onClick={() => handleCardClick(org._id)}
                            >
                                <div className="flex flex-col md:flex-row md:items-center md:justify-between">
                                    <div className="flex flex-col flex-1 gap-2">
                                        <h3 className="text-xl font-semibold text-gray-800">{org.nameOrg}</h3>
                                        <div className="flex-1">
                                            <p className=" text-sm font-semibold text-gray-500">Адрес организации:</p>
                                            <p className="text-gray-800 mt-1">{org.addressOrg}</p>
                                        </div>
                                        <div className="flex-1">
                                            <p className=" text-sm font-semibold text-gray-500">Описание:</p>
                                            <p className="text-gray-800 mt-1">{org.descriptionOrg}</p>
                                        </div>
                                    </div>
                                    <div className="mt-4 md:mt-0 md:ml-6 flex-shrink-0">
                                        <div className="flex-1">
                                            <p className=" text-sm font-semibold text-gray-500">ИНН организации:</p>
                                            <p className="text-gray-800 mt-1">{org.innOrg}</p>
                                        </div>
                                        <div className="flex-1">
                                            <p className=" text-sm font-semibold text-gray-500">Телефон:</p>
                                            {org.phoneOrg.map((phone, index) => (
                                                <p key={index} className="text-gray-800 mt-1">{phone}</p>
                                            ))}
                                        </div>
                                        <div className="flex-1">
                                            <p className=" text-sm font-semibold text-gray-500">Email:</p>
                                            <p className="text-gray-800 mt-1">{org.emailOrg}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </motion.div>

    );
};

export default OrganizationList;
