
import styles from './centered_vertical_form.module.scss';

export const CenteredVerticalForm = (props) => {
  return (
    <div className={styles.container}>
      <div className={styles.form}>
        {props.children}
      </div>
    </div>
  );
};