import $api from '../http/index';

export default class AuthService {
    static async login(phone, password) {
        return $api.post('/login', { phone, password });
    }

    static async registration(phone, email, password) {
        return $api.post('/registration', { email, phone, password });
    }

    static async logout() {
        return $api.post('/logout');
    }
}