import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

const CartidgesList = () => {
    const navigate = useNavigate();
    const { brandId } = useParams(); // Получаем id бренда из параметров маршрута
    const [cartidges, setCartidges] = useState([]); // Состояние для хранения списка картриджей

    useEffect(() => {
        // Функция для получения списка картриджей по бренду
        const fetchCartidges = async () => {
            try {
                const response = await fetch(`https://lk.eq74.ru/api/cart/${brandId}`);
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const data = await response.json();
                setCartidges(data); // Обновляем состояние списка картриджей
            } catch (error) {
                console.error("Не удалось загрузить список картриджей:", error);
            }
        };

        fetchCartidges(); // Вызываем функцию при монтировании компонента
    }, [brandId]); // Зависимость от brandId, чтобы обновить список при изменении id бренда

    return (
        <div>
            <button
                className='flex relative h-10 w-20 shadow-sm hover:shadow-lg bg-slate-900 transform ease-in-out duration-75 hover:bg-slate-700 text-slate-50 justify-center text-center content-center items-center rounded-lg'
                onClick={() => navigate(-1)}
            ></button>
            <h1>Список картриджей</h1>

            <ul>
                {cartidges.map((cartidge, index) => (
                    <li className="flex items-center border p-4 my-2" key={index}>
                        <img src="cartridge-icon.png" alt="Cartridge Icon" className="w-12 h-12 mr-4" />
                        <div>
                            <span className="block font-bold">{cartidge.nameCart}</span>
                            <table className="table-auto">
                                <thead>
                                    <tr>
                                        <th className="px-4 py-2">Заправка</th>
                                        <th className="px-4 py-2">Чип</th>
                                        <th className="px-4 py-2">Фотобарабан</th>
                                        <th className="px-4 py-2">Лезвие</th>
                                        <th className="px-4 py-2">Ролики</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className="px-4 py-2">{cartidge.cenaZapr} руб.</td>
                                        <td className="px-4 py-2">{cartidge.cenaChip} руб.</td>
                                        <td className="px-4 py-2">{cartidge.cenaFoto} руб.</td>
                                        <td className="px-4 py-2">{cartidge.cenaLezv} руб.</td>
                                        <td className="px-4 py-2">{cartidge.cenaRolica} руб.</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="ml-auto text-right">
                            <span className="block">Цена за картридж: {cartidge.cenaCart} руб.</span>
                            <span className="block">Ресурс: {cartidge.resurs}</span>
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default CartidgesList;
