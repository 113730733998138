// CardModal.js

import React from 'react';

const CardModal = ({ card, onClose }) => {
    return (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-900 bg-opacity-50">
            <div className="bg-white p-4">
                <p>Телефон: {card.phone}</p>
                <p>Имя: {card.name}</p>
                <p>Модель устройства: {card.model}</p>
                <p>Адрес: {card.adress}</p>
                <p>Описание: {card.opisanie}</p>
                <p>Статус: {card.status}</p>
                <p>Дата: {card.data}</p>
                <button onClick={onClose} className="bg-red-500 text-white px-4 py-2 mt-4">Close</button>
            </div>
        </div>
    );
};

export default CardModal;